<template>
  <form action="#" class="form" data-gw-tab="support" data-gw-tab-grp="form">
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__flag.png" alt="" />
        </div>
        <div class="field__title">Choose</div>
      </div>
      <select
        class="tt-uppercase"
        @change="service_obj_change"
        v-model="service"
      >
        <template v-for="(service, index) in services" :key="index">
          <option :value="service.value">{{ service.name }}</option>
        </template>
      </select>
    </div>
    <!-- END  field -->

    <div class="form__btn-box">
      <template v-if="this.service_obj.is_link">
        <a :href="this.service_obj.link" target="_blank" class="button">
          <span class="button__content">Continue</span>
        </a>
      </template>
      <template v-else>
        <router-link :to="{ path: '/' + service }" class="button">
          <span class="button__content">Continue</span>
        </router-link>
      </template>
    </div>
  </form>
</template>

<script>
// import { __config } from "@/assets/js/config";

export default {
  name: "Support",
  data() {
    return {
      service: "info",
      service_obj: {
        value: "info",
        name: "Information",
        is_link: true,
        link: this.$store.getters.get_servers[
          this.$store.getters.get_default_server - 1
        ].desc_link,
      },
      services: [
        { value: "changePassword", name: "Change password", is_link: false },
        {
          value: "restorePassword",
          name: "Restore password",
          is_link: false,
        },
        { value: "changeEmail", name: "Change E-mail", is_link: false },
        { value: "unbind", name: "Unbind HWID/IP", is_link: false },
        {
          value: "restoreUsername",
          name: "Restore ID",
          is_link: false,
        },
        { value: "vote", name: "Vote", is_link: false },
        { value: "contacts", name: "Contacts", is_link: false },
        // TODO: Create links for those 2 menus
        {
          value: "info",
          name: "Information",
          is_link: true,
          link:
            this.$store.getters.get_servers[
              this.$store.getters.get_default_server - 1
            ].desc_link || "",
        },
        {
          value: "faq",
          name: "FAQ",
          is_link: true,
          link: "https://forum.la2dream.com/forum/5-faq/",
        },
      ],
    };
  },
  methods: {
    service_obj_change() {
      this.service_obj =
        this.services[
          this.services.findIndex((item) => item.value === this.service)
        ];
    },
  },
};
</script>

<style scoped></style>
